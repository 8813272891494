<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Company Information
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels multiple v-model="panels">
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
            >
              <v-expansion-panel-header>
                <h3>
                  User Information
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: `/dashboard/admin/user/edit/${userData.id}`,
                      })
                    "
                    >mdi-account-edit-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div>
                  <v-simple-table dense>
                    <thead>
                      <th :width="450"></th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          {{ userData.first_name }} {{ userData.last_name }}
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ userData.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{{ userData.phone }}</td>
                      </tr>
                      <tr>
                        <td>Send Emails on Opportunities</td>
                        <td>
                          {{ userData.send_summary_offer_email | yes_no }}
                        </td>
                      </tr>
                      <tr>
                        <td>Blocked Status</td>
                        <td>{{ userData.blocked | yes_no }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <br />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
              mandatory
            >
              <v-expansion-panel-header
                >Associated Companies</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-alert type="error" v-if="!userData.companies.length">YOU MUST ASSOCIATE THIS USER TO A COMPANY OR THEY WILL HAVE ERRORS WHEN LOGGING IN. DO NOT MISS THIS STEP! <br> <br> <v-btn @click="$router.push({path: '/dashboard/admin/company/list'})">List Companies</v-btn> &nbsp; <v-btn @click="$router.push({path: '/dashboard/admin/company/add'})">New Company</v-btn></v-alert>
                <div v-for="company in userData.companies" :key="company.id" style="cursor:pointer;" @click="$router.push({path: `/dashboard/admin/company/read/${company.id}`})">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <th style="width: 250px"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company Name:</td>
                          <td style="text-transform: capitalize">
                            {{ company.name }}
                          </td>
                        </tr>
                        <tr>
                          <td>Lender:</td>
                          <td>{{ company.lender | yes_no }}</td>
                        </tr>
                        <tr>
                          <td>Appraiser:</td>
                          <td>{{ company.appraiser | yes_no }}</td>
                        </tr>
                        <tr>
                          <td>Lawyer:</td>
                          <td>{{ company.lawyer | yes_no }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

export default {
  name: "admin_user_read",
  props: ["user_id"],
  components: {
    // insertNote: () => import("@/components/note/InsertNote.vue"),
    // Note: () => import("@/components/note/Note.vue"),
  },
  data() {
    return {
      panels: [0,1],

      componentLoading: true,

      userData: {},
    };
  },
  methods: {},
  async mounted() {
    try {
      const userData = await API().get(
        `/api/internal-admin/get_user_info_view/${this.user_id}`
      );
      console.log(userData);
      if (userData.status == 200) {
        this.userData = userData.data;
      }
    } catch (error) {
      console.error(error);
    }

    this.componentLoading = false;
  },
  filters: {
    ...filters,
  },
};
</script>

<style lane="scss" scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
tr:hover {
    background-color: transparent !important;
  }
</style>